import { makeStyles, mergeClasses, shorthands, Skeleton, SkeletonItem, tokens } from '@fluentui/react-components';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TeamsFxContext } from 'tab/contexts';
import { LanguageSelector } from '../../../app/components/LanguageSelector';
import './Header.sass';

const useStyle = makeStyles({
  root: {
    backgroundColor: tokens.colorBrandStroke2Contrast,
    'grid-column-gap': tokens.spacingHorizontalMNudge,
    'padding-inline': tokens.spacingHorizontalXL,
  },
  rootContrast: {
    ...shorthands.borderBottom('1px', 'solid', tokens.colorNeutralStrokeAccessible),
  },
});

type Props = {
  loading: boolean;
};

const Header = ({ loading }: Props) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const { themeString } = useContext(TeamsFxContext);
  const className = useMemo(() => (themeString == 'contrast' ? mergeClasses(classes.root, classes.rootContrast) : classes.root), [themeString]);
  return (
    <header id="header" className={className}>
      <h2 className="name">
        {loading ? (
          <Skeleton>
            <SkeletonItem className="skeleton-header" size={20}></SkeletonItem>
          </Skeleton>
        ) : (
          t('appName')
        )}
      </h2>

      {loading ? (
        <Skeleton className="language-selector skeleton-language">
          <SkeletonItem shape="rectangle" size={20}></SkeletonItem>
        </Skeleton>
      ) : (
        <LanguageSelector />
      )}
    </header>
  );
};

export default Header;
