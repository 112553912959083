import { FluentProvider } from '@fluentui/react-components';
import { app } from '@microsoft/teams-js';
import { useTeamsUserCredential } from '@microsoft/teamsfx-react';
import { useEffect, useState } from 'react';
import { config } from '../../../lib/config';
import { TeamsFxContext } from '../../contexts';
import { Main } from '../Main';

const Tab = () => {
  const context = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint,
    clientId: config.clientId,
  });

  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (context.loading) {
      app.initialize().then(() => {
        app.notifySuccess();
        setInitialized(true);
      });
    }
  }, [context.loading]);

  return (
    <TeamsFxContext.Provider value={context}>
      <FluentProvider theme={context.theme}>{!context.loading && initialized && <Main />}</FluentProvider>
    </TeamsFxContext.Provider>
  );
};

export default Tab;
