import i18next from 'i18next';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';

const LanguageDetector = () => {
  const currLang = i18next.language;
  const { locale } = useParams();
  const { pathname, search, hash } = useLocation();
  return locale ? <Outlet /> : <Navigate to={`${currLang}${pathname}${search}${hash}`} />;
};

export default LanguageDetector;
