import {
  Button,
  Caption1,
  Card,
  CardHeader,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  makeStyles,
  mergeClasses,
  TableRowId,
  Text,
  tokens,
} from '@fluentui/react-components';

import { MoreHorizontal20Regular } from '@fluentui/react-icons';
import { AssignmentDto } from 'models/assignments';
import { ImgAuth } from '../ImgAuth';
import { getAssignmentsList } from './constants';

import './AssignmentsViewer.sass';
import { useTranslation } from 'react-i18next';
import { View } from '../ViewSwitcher';

const useStyles = makeStyles({
  caption: {
    color: tokens.colorNeutralForeground3,
  },
});

type Props = {
  view: View;
  filtered: AssignmentDto[];
  onSelect?: (assignment: AssignmentDto) => void;
};

const AssignmentsViewer = ({ view, filtered, onSelect }: Props) => {
  const styles = useStyles();
  const classNameClass = mergeClasses('card__class', styles.caption);

  const selectAssignment = (id: string) => {
    const item = filtered?.find((x) => x.id === id);
    if (item) {
      onSelect?.(item);
    }
  };

  const { t } = useTranslation();

  if (view == 'table') {
    return (
      <DataGrid
        items={filtered}
        columns={getAssignmentsList(t)}
        sortable
        subtleSelection
        selectionMode="single"
        getRowId={(item: AssignmentDto) => item.id}
        onSelectionChange={(e, data) => {
          const values: IterableIterator<TableRowId> = data.selectedItems.values();
          return selectAssignment(values.next().value);
        }}
        focusMode="composite"
        className="table__main"
      >
        <DataGridHeader>
          <DataGridRow selectionCell={{ 'aria-label': 'Select all rows' }}>
            {({ renderHeaderCell }) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody<AssignmentDto>>
          {({ item, rowId }) => (
            <DataGridRow<AssignmentDto> key={rowId} selectionCell={{ 'aria-label': 'Select row' }}>
              {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
    );
  }

  return (
    <div className="assignments">
      {filtered?.map((assignment) => (
        <Card className="card__main" key={assignment.id} onClick={() => onSelect?.(assignment)}>
          <CardHeader
            image={<ImgAuth className="card__headerImage" src={`https://graph.microsoft.com/v1.0/teams/${assignment.classId}/photo/$value`} alt="Class" />}
            header={
              <div className="card__name">
                <Text weight="semibold">{assignment.displayName}</Text>
              </div>
            }
            description={
              <div className={classNameClass}>
                <Caption1>{assignment.className}</Caption1>
              </div>
            }
            action={<Button appearance="transparent" icon={<MoreHorizontal20Regular />} aria-label="More options" />}
          />
          <div className="card__text">
            <span>{assignment.instructionContent}</span>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default AssignmentsViewer;
