import { useBackendApi } from 'lib/backendApi/useBackendApi';
import { ImgHTMLAttributes } from 'react';

const cache = {} as { [url: string]: Promise<string> | undefined };

const ImgAuth = (props: ImgHTMLAttributes<HTMLImageElement>) => {
  const { loading, data } = useBackendApi<string | undefined>(
    async (api) => {
      if (!props.src) {
        return undefined;
      }
      if (cache[props.src]) {
        return await cache[props.src];
      }
      const promise = api.getImage().then((blob) => URL.createObjectURL(blob));
      cache[props.src] = promise;
      return await promise;
    },
    { baseUrl: props.src }
  );
  const alt = loading ? '' : props.alt;
  return <img {...props} src={data} alt={alt} />;
};

export default ImgAuth;
