import { makeStyles, Skeleton, SkeletonItem } from '@fluentui/react-components';

const useStyles = makeStyles({
  firstRow: {
    marginBottom: '10px',
  },
  skeleton: {
    opacity: 0.5,
  },
});

const SkeletonAssignmentScreen = () => {
  const styles = useStyles();
  return (
    <Skeleton className={styles.skeleton}>
      <SkeletonItem className={styles.firstRow} size={32} />
      <div className="assignments">
        {Array.from({ length: 32 }, (_, i) => (
          <SkeletonItem key={i} size={128} />
        ))}
      </div>
    </Skeleton>
  );
};

export default SkeletonAssignmentScreen;
