import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';
import { LanguageSelector } from './LanguageSelector';
import { useTranslation } from 'react-i18next';

const Privacy = () => {
  const { t } = useTranslation();
  return (
    <FluentProvider theme={teamsLightTheme}>
      <LanguageSelector />
      <div>
        <h1>{t('privacyStatement')}</h1>
      </div>
    </FluentProvider>
  );
};

export default Privacy;
