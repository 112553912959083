import { Dropdown, Option } from '@fluentui/react-components';
import i18next from 'i18next';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { languages } from './constants';
import './LanguageSelector.sass';

const LanguageSelector = () => {
  const [currLang, setCurrLang] = useState(i18next.language);

  const navigate = useNavigate();
  const { pathname, search, hash } = useLocation();

  const changeLanguage = (newLang: string) => {
    i18next.changeLanguage(newLang);
    setCurrLang(newLang);
    const path = pathname.substring(pathname.indexOf('/', 1));
    navigate(`/${newLang}${path}${search}${hash}`);
  };

  const countryCode = useMemo(() => languages.find((x) => x.code == currLang)?.countryCode, [currLang]);

  return (
    <Dropdown
      className="language-selector"
      button={<span className={`flag-icon flag-icon-${countryCode}`}></span>}
      expandIcon={null}
      onOptionSelect={(_, data) => changeLanguage(data.optionValue ?? 'en')}
      defaultSelectedOptions={[currLang]}
    >
      {languages.map((lang) => (
        <Option checkIcon={null} key={lang.countryCode} text={lang.name} value={lang.code} disabled={lang.code === currLang}>
          <>
            <span className={`flag-icon flag-icon-${lang.countryCode}`} style={{ opacity: lang.code === currLang ? 0.5 : 1 }}></span>
            {lang.name}
          </>
        </Option>
      ))}
    </Dropdown>
  );
};

export default LanguageSelector;
