import { Button, useId } from '@fluentui/react-components';
import { useGraphWithCredential } from '@microsoft/teamsfx-react';
import { LanguageSelector } from 'app/components/LanguageSelector';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { scope } from 'tab/constants';
import { TeamsFxContext } from 'tab/contexts';
import { Content } from '../Content';
import { Header } from '../Header';
import { PopUp } from '../PopUp';
import { SkeletonAssignmentScreen } from '../SkeletonAssignmentScreen';

import './Main.sass';

const Main = () => {
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { loading, data, reload } = useGraphWithCredential(
    async (graph) => {
      return await graph.api('/me').get();
    },
    { scope, credential: teamsUserCredential }
  );
  const titleId = useId('title');
  const { t } = useTranslation();
  return (
    <>
      <Header loading={loading || !data} />
      <main id="main">{loading || !data ? <SkeletonAssignmentScreen /> : <Content />}</main>

      <PopUp popupClass="auth-modal" isOpen={!data && !loading} titleAriaId={titleId}>
        <div className="auth-modal__header">
          <h2 id={titleId}>{t('authModalHeader')}</h2>
          <LanguageSelector />
        </div>
        <div className="auth-modal__content">
          <p>{t('authModalText')}</p>
          <span className="auth-modal__content__help-text">{t('authModalHelpText')}</span>
        </div>
        <div className="auth-modal__footer">
          <Button appearance="primary" onClick={reload}>
            {t('authButton')}
          </Button>
        </div>
      </PopUp>
    </>
  );
};

export default Main;
