
export const languages = [
  {
    code: 'en',
    name: 'English',
    countryCode: 'gb'
  },
  {
    code: 'uk',
    name: 'Українська',
    countryCode: 'ua'
  }
];
