type ResType = 'json' | 'text' | 'blob';

export class BackendApi implements IBackendApi {
  constructor(private token: string, private baseUrl: string) {}

  getImage(url = '') {
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    };
    return fetch(this.makePath(url), requestOptions).then((x) => this.handleResponse<Blob>(x, 'blob'));
  }

  get<T>(url = '') {
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.token}`,
        Accept: 'application/json',
      },
    };
    return fetch(this.makePath(url), requestOptions).then((x) => this.handleResponse<T>(x));
  }

  post<T, K = any>(url = '', body?: K) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
        Accept: 'application/json',
      },
      body: body ? JSON.stringify(body) : undefined,
    };
    return fetch(this.makePath(url), requestOptions).then((x) => this.handleResponse<T>(x));
  }

  put<T, K = any>(url = '', body?: K) {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
        Accept: 'application/json',
      },
      body: body ? JSON.stringify(body) : undefined,
    };
    return fetch(this.makePath(url), requestOptions).then((x) => this.handleResponse<T>(x));
  }

  delete<T>(url = '') {
    const requestOptions: RequestInit = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${this.token}`,
        Accept: 'application/json',
      },
    };
    return fetch(this.makePath(url), requestOptions).then((x) => this.handleResponse<T>(x));
  }

  private makePath(url: string) {
    if (!url) {
      return this.baseUrl;
    }
    url = url.replaceAll('\\', '/');
    if (url[0] == '/') {
      return this.baseUrl + url;
    }
    return `${this.baseUrl}/${url}`;
  }

  private async handleResponse<T>(response: Response, type: ResType = 'json') {
    let data;
    try {
      switch (type) {
        case 'json':
          data = await response.json();
          break;
        case 'text':
          data = await response.text();
          break;
        case 'blob':
          data = await response.blob();
          break;
      }
    } catch {
      /* empty */
    }

    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      throw new Error(error);
    }
    return data as T;
  }
}

export interface IBackendApi {
  getImage(url?: string): Promise<Blob>;
  get<T>(url?: string): Promise<T>;
  post<T, K = any>(url?: string, body?: K): Promise<T>;
  put<T, K = any>(url?: string, body?: K): Promise<T>;
  delete<T>(url?: string): Promise<T>;
}
