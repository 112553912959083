import { createTableColumn, TableColumnDefinition } from '@fluentui/react-components';
import { AssignmentDto } from 'models/assignments';
import { ImgAuth } from '../ImgAuth';

export const getAssignmentsList = (t: (token: string) => string) => [
  createTableColumn<AssignmentDto>({
    columnId: 'name',
    renderHeaderCell: () => <div>{t('assignmentViewerColumnName')}</div>,
    compare: (a, b) => b.displayName.localeCompare(a.displayName),
    renderCell: (item) => (
      <div className="table__text">{item.displayName}</div>
    ),
  }),
  createTableColumn<AssignmentDto>({
    columnId: 'class',
    renderHeaderCell: () => <div>{t('assignmentViewerClassName')}</div>,
    compare: (a, b) => b.className.localeCompare(a.className),
    renderCell: (item) => <>
      <ImgAuth className="table__headerImage" src={`https://graph.microsoft.com/v1.0/teams/${item.classId}/photo/$value`} alt="Class" />
      <div className="table__text">{item.className}</div>
    </>,
  }),
  createTableColumn<AssignmentDto>({
    columnId: 'datePosted',
    renderHeaderCell: () => <div>{t('assignmentViewerDataCreated')}</div>,
    compare: (a, b) => (b.createdDateTime?.getTime() ?? 0) - (a.createdDateTime?.getTime() ?? 0),
    renderCell: (item) => <div className="table__text">{item.createdDateTime?.toUTCString()}</div>,
  }),
  createTableColumn<AssignmentDto>({
    columnId: 'text',
    renderHeaderCell: () => <div>{t('assignmentViewerText')}</div>,
    compare: (a, b) => b.instructionContent.localeCompare(a.instructionContent),
    renderCell: (item) => <div className="table__text">{item.instructionContent}</div>,
  }),
] as TableColumnDefinition<AssignmentDto>[];
