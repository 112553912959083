import { Spinner, Switch } from '@fluentui/react-components';
import { Dismiss20Regular } from '@fluentui/react-icons';
import { SearchBox } from '@fluentui/react-search-preview';
import Fuse from 'fuse.js';
import { useBackendApi } from 'lib/backendApi/useBackendApi';
import { AssignmentDto } from 'models/assignments';
import { useMemo, useState } from 'react';
import { AssignmentPopUp } from '../AssignmentPopUp';
import { AssignmentsViewer } from '../AssignmentsViewer';
import { SkeletonAssignmentScreen } from '../SkeletonAssignmentScreen';
import { View, ViewSwitcher } from '../ViewSwitcher';

import './Content.sass';
let isSynced = false;

const Content = () => {
  const [filtered, setFiltered] = useState([] as AssignmentDto[]);
  const [view, setView] = useState<View>('card');
  const [selectedAssignment, setSelectedAssignment] = useState<AssignmentDto>({} as AssignmentDto);
  const [openPopUp, setOpenPopUp] = useState(false);
  const {
    data: assignments,
    loading,
    reload,
  } = useBackendApi<AssignmentDto[]>(async (api) => {
    let res = await api.get<AssignmentDto[]>('/Assignments');
    res = res.map((x) => ({
      ...x,
      createdDateTime: new Date(x.createdDateTime),
    }));
    setFiltered(res);
    return res;
  });
  const syncRes = useBackendApi(async (api) => {
    if (isSynced) {
      return;
    }
    await api.post<void>('/Assignments');
    isSynced = true;
    reload();
  });

  const fuse = useMemo(
    () =>
      new Fuse(assignments ?? [], {
        threshold: 0.2,
        isCaseSensitive: false,
        shouldSort: true,
        ignoreLocation: true,
        keys: ['displayName', 'className', 'instructionContent'],
      }),
    [assignments]
  );
  const filter = (query: string) => {
    if (!query) {
      setFiltered(assignments ?? []);
    } else {
      const res = fuse.search(query);
      setFiltered(res.map((x) => x.item));
    }
  };

  if (loading) {
    return <SkeletonAssignmentScreen />;
  }
  return (
    <>
      <div className="search-block">
        <SearchBox className="search-block__search" onChange={(_, data) => filter(data.value)} dismiss={<Dismiss20Regular onClick={() => filter('')} />} />
        <ViewSwitcher view={view} onChange={setView} />
        {/* <Switch label={view ? <span>&nbsp;Card&nbsp;view</span> : <span>Table&nbsp;view</span>} checked={view} onChange={(_, data) => setView(data.checked)} /> */}
        {syncRes.loading && <Spinner size="tiny" label="Synchronizing..." />}
      </div>
      <AssignmentsViewer
        view={view}
        filtered={filtered}
        onSelect={(assignment) => {
          setSelectedAssignment(assignment);
          setOpenPopUp(true);
        }}
      />
      <AssignmentPopUp isOpen={openPopUp} assignment={selectedAssignment} onClose={() => setOpenPopUp(false)} />
    </>
  );
};

export default Content;
