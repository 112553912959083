import i18next from 'i18next';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import Tab from '../../tab/components/Tab/Tab';
import LanguageDetector from './LanguageDetector';
import Privacy from './Privacy';
import TabConfig from './TabConfig';
import TermsOfUse from './TermsOfUse';

const App = () => {
  const currLang = i18next.language;
  return (
    <Router>
      <Routes>
        <Route path=":locale?" element={<LanguageDetector />}>
          <Route path="privacy" element={<Privacy />} />
          <Route path="termsofuse" element={<TermsOfUse />} />
          <Route path="tab" element={<Tab />} />
          <Route path="config" element={<TabConfig />} />
        </Route>
        <Route path="*" element={<Navigate to={`${currLang}/tab`} />}></Route>
      </Routes>
    </Router>
  );
};

export default App;
