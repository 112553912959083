import { config } from './../config';
import { BackendApi, IBackendApi } from './BackendApi';
import { useData } from '@microsoft/teamsfx-react';
import { useContext } from 'react';
import { scope } from 'tab/constants';
import { TeamsFxContext } from 'tab/contexts';

export type Options = {
  baseUrl?: string;
  autoLoad?: boolean;
};

export const useBackendApi = <T>(fetchDataAsync: (api: IBackendApi) => Promise<T>, options?: Options) => {
  const { teamsUserCredential } = useContext(TeamsFxContext);
  return useData<T | undefined>(
    async () => {
      const { token } = (await teamsUserCredential?.getToken(scope)) ?? {};
      if (!token) {
        throw new Error('Cannot get auth token');
      }
      const backendApi = new BackendApi(token, options?.baseUrl ?? config.backendBaseUrl);
      return await fetchDataAsync(backendApi);
    },
    { autoLoad: options?.autoLoad ?? true }
  );
};
