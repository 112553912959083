import { Modal, IModalProps } from '@fluentui/react';
import { FluentProvider, makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';
import { useContext } from 'react';
import { TeamsFxContext } from 'tab/contexts';

import './PopUp.sass';

type Props = Exclude<IModalProps, 'isDarkOverlay'> & {
  popupClass?: string;
  size?: 's' | 'm' | 'l' | 'xl';
};

const useStyles = makeStyles({
  modalBorderContrast: {
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStrokeAccessible),
  },
});

const PopUp = (props: Props) => {
  const { theme, themeString } = useContext(TeamsFxContext);
  const styles = useStyles();

  let classWrapper: string | undefined;

  if (themeString == 'contrast') {
    classWrapper = props.popupClass ? mergeClasses(props.popupClass, styles.modalBorderContrast) : mergeClasses('popup', styles.modalBorderContrast);
  } else {
    classWrapper = props.popupClass ?? 'popup';
  }

  const size = props.size ?? 'medium';

  return (
    <Modal {...props} isDarkOverlay={themeString != 'dark'} containerClassName={'popup-' + size}>
      <FluentProvider theme={theme}>
        <div className={classWrapper}>{props.children}</div>
      </FluentProvider>
    </Modal>
  );
};

export default PopUp;
