import { Button, Tooltip } from '@fluentui/react-components';
import { AppsList24Regular, Grid24Regular } from '@fluentui/react-icons';
import { useState } from 'react';
import { View } from './models';

import './ViewSwitcher.sass';

type Props = {
  view?: View;
  onChange?: (view: View) => void;
};

const views = {
  card: {
    icon: <Grid24Regular />,
    tooltip: 'To table view',
  },
  table: {
    icon: <AppsList24Regular />,
    tooltip: 'To card view',
  },
};

const ViewSwitcher = ({ onChange, view }: Props) => {
  const [viewInner, setView] = useState<View>(view ?? 'card');

  const changeView = () => {
    const newView = viewInner == 'table' ? 'card' : 'table';
    onChange?.(newView);
    setTimeout(() => setView(newView), 0);
  };

  return (
    <Tooltip withArrow content={views[viewInner].tooltip} relationship="label" hideDelay={250} showDelay={1000}>
      <Button className="view-switcher__switch-button" appearance="transparent" icon={views[viewInner].icon} onClick={changeView} />
    </Tooltip>
  );
};

export default ViewSwitcher;
