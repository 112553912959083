import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app/components/App';
import { initI18n } from './lib/i18n';

import './index.sass';
import 'flag-icon-css/css/flag-icons.min.css';

initI18n();

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <App />
  );
}
