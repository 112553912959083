import { Button, SkeletonItem, useId } from '@fluentui/react-components';
import { AssignmentDetailedDto, AssignmentDto } from 'models/assignments';
import { PopUp } from '../PopUp';
import { Dismiss24Regular } from '@fluentui/react-icons';

import './AssignmentPopUp.sass';
import { useBackendApi } from 'lib/backendApi/useBackendApi';
import { useEffect } from 'react';

type Props = {
  assignment: AssignmentDto;
  isOpen: boolean;
  onClose: () => void;
};

const AssignmentPopUp = ({ isOpen, assignment, onClose }: Props) => {
  const titleId = useId('title');
  const { data, loading, reload } = useBackendApi(
    async (api) => {
      if (!assignment?.id) {
        return undefined;
      }
      const res = await api.get<AssignmentDetailedDto>(`Assignments/${assignment.id}`);
      return {
        ...res,
        createdDate: new Date(res.createdDate),
        dueDate: res.dueDate ? new Date(res.dueDate) : undefined,
        closeDate: res.closeDate ? new Date(res.closeDate) : undefined,
        assignDate: res.assignDate ? new Date(res.assignDate) : undefined,
        assignedDate: res.assignedDate ? new Date(res.assignedDate) : undefined,
        lastModifiedDate: res.lastModifiedDate ? new Date(res.lastModifiedDate) : undefined,
      };
    },
    { autoLoad: false }
  );
  useEffect(() => reload(), [assignment]);
  return (
    <PopUp isOpen={isOpen} titleAriaId={titleId} size="xl" popupClass="assgnments-popup popup">
      <div className="popup-header">
        <h2 id={titleId}>{assignment.displayName}</h2>
        <Button appearance="transparent" onClick={onClose} icon={<Dismiss24Regular />} aria-label="More options" />
      </div>
      <div className="popup-content assignment-popup__content">
        {loading ? (
          <>
            <SkeletonItem size={128} />
            <SkeletonItem size={128} />
          </>
        ) : (
          <>
            <div>
              {data?.instructionContentHtml ? <div dangerouslySetInnerHTML={{ __html: data.instructionContentHtml }}></div> : data?.instructionContentText}
            </div>
            <div>Class: {assignment.className}</div>
          </>
        )}
      </div>
      <div className="popup-footer">
        <Button appearance="primary">Create from this</Button>
      </div>
    </PopUp>
  );
};

export default AssignmentPopUp;
