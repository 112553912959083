
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

let inited = false;

export const initI18n = () => {
  if (!inited) {
    i18n
      .use(initReactI18next)
      .use(LanguageDetector)
      .use(HttpApi)
      .init({
        supportedLngs: ['en', 'uk'],
        fallbackLng: 'en',
        detection: {
          order: ['path', 'localStorage', 'navigator'],
          caches: ['localStorage']
        },
        backend: {
          loadPath: '/assets/locales/{{lng}}/translation.json'
        },
        react: {
          useSuspense: false
        }
      });
    inited = true;
  }
};

