export const scope = [
  'User.Read',
  'User.ReadWrite',
  'User.Read.All',
  'User.ReadWrite.All',
  'User.ReadBasic.All',
  'Directory.ReadWrite.All',
  'Sites.Read.All',
  'Application.Read.All',
  'Application.ReadWrite.All',
  'Files.ReadWrite',
];
